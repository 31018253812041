<template>
  <v-container fluid>
    <loading-overlay :loading="loading" />
    <v-card tile flat>
      <skeleton-page-heading v-if="boothTypes.length === 0" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <h2
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'tw-text-gray-800 tw-text-xl tw-flex tw-font-medium tw-capitalize'
              : 'tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize'
          "
        >
          <v-icon class="tw-text-gray-800 tw-mr-2"
            >mdi-account-plus-outline</v-icon
          >
          {{ title }}
        </h2>
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" md="6" lg="6">
            <skeleton-form :count="5" v-if="boothTypes.length === 0" />
            <v-form v-else @submit.prevent="submit">
              <v-select
                v-model="form.booth_type_id"
                label="Booth Type"
                dense
                outlined
                readonly
                :items="boothTypes"
                item-text="type"
                item-value="id"
                :disabled="loading"
                :error-messages="errors.booth_type"
              ></v-select>
              <div>
                 
                <label class="tw-text-gray-900">Number Type</label>
                <v-radio-group disabled mandatory v-model="form.number_type" row>
                  <v-radio label="Type 1" :value="1"></v-radio>
                  <v-radio label="Type 2" :value="2"></v-radio>
                  <v-radio label="Type 3" :value="3"></v-radio>
                  <v-radio label="Type 4" :value="4"></v-radio>
                </v-radio-group>
              </div>

              <div>
                <label class="tw-text-gray-900">Top</label>
                <v-slider
                  v-model="form.top"
                  dense
                  thumb-label
                  max="100"
                  min="0"
                  step="0.1"
                  track-color="primary"
                  thumb-color="primary"
                ></v-slider>
              </div>

              <div>
                <label class="tw-text-gray-900">Left</label>
                <v-slider
                  v-model="form.left"
                  dense
                  thumb-label
                  max="100"
                  min="0"
                  step="0.1"
                  track-color="primary"
                  thumb-color="primary"
                ></v-slider>
              </div>

              <div>
                <label class="tw-text-gray-900">Width</label>
                <v-slider
                  v-model="form.width"
                  dense
                  thumb-label
                  max="100"
                  min="0"
                  step="0.1"
                  track-color="primary"
                  thumb-color="primary"
                ></v-slider>
              </div>

              <div>
                <label class="tw-text-gray-900">Height</label>
                <v-slider
                  v-model="form.height"
                  dense
                  thumb-label
                  max="100"
                  min="0"
                  step="0.1"
                  track-color="primary"
                  thumb-color="primary"
                ></v-slider>
              </div>

              <div class="tw-text-right">
                <v-btn
                  depressed
                  class="tw-capitalize tw-tracking-wide"
                  color="primary"
                  max-width="200"
                  width="120"
                  type="submit"
                  :loading="loading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-skeleton-loader
              v-if="boothTypes.length === 0"
              class="mx-auto"
              width="250"
              type="image"
            ></v-skeleton-loader>
            <v-img
              v-if="Object.keys(selectedBooth).length > 0"
              :src="selectedBooth.booth_thumbnail"
            >
              <div
                class="tw-absolute tw-z-10 tw-cursor-pointer tw-border-2 tw-border-red-600 tw-border-solid"
                :style="
                  `
              top: ${form.top}%;
              left: ${form.left}%;
              width: ${form.width}%;
              height: ${form.height}%; `
                "
              ></div>
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import form from "@/mixins/form";
import page from "../mixins/page";
import Swal from "sweetalert2";
import { boothTypes, detailCoordinate, updateBoothType, detailBoothType } from "@/api/booth";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";
import SkeletonForm from "../components/SkeletonForm.vue";

export default {
  components: {
    BreadcrumbComponent,
    LoadingOverlay,
    SkeletonPageHeading,
    SkeletonForm,
  },
  mixins: [form, page],
  data() {
    return {
      boothTypes: [],
      boothCoordinate: [],
      selectedBooth: {}
    };
  },
  computed: {
    form: {
      get() {
        return this.boothCoordinate;
      },
    },
  },
  async mounted() {
    await this.getBootTypes();
    await this.getDetailCoordinate();
    await this.getDetailBoothType();
  },
  methods: {
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");
        await updateBoothType(this.form);
        await Swal.fire({
          icon: "success",
          title: "Berhasil mengubah data",
          timer: 1500,
        });
        this.$store.dispatch("auth/setLoading");
        this.errors = {};
        this.$router.push({ name: "booth-coordinate" });
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          console.log(error.response);
          if (error.response.data.statusCode === 422) {
            Swal.fire({
              icon: "error",
              title: error.response.data.errors,
              timer: 1500,
            });
          } else {
            this.errors = error.response.data.errors;
          }
        }
      }
    },
    async getBootTypes() {
      const {
        data: { data },
      } = await boothTypes();

      this.boothTypes = data;
    },
    async getDetailCoordinate() {
      const {
        data: { data },
      } = await detailCoordinate(this.$route.params.id);
      
      const boothTypeId = parseInt(data.booth_type_id)
      const numberType = parseInt(data.number_type)
      this.boothCoordinate = data;
      this.boothCoordinate.booth_type_id = boothTypeId
      this.boothCoordinate.number_type = numberType
      
    },
    async getDetailBoothType(){
      const data = await detailBoothType({id: this.form.booth_type_id})

      this.selectedBooth = data
    }
  },
};
</script>

<style scoped>
::v-deep .v-slider--horizontal {
  margin-left: 7px !important;
}
</style>
